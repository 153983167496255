(function ($, App) {

    function BackToTop() {
        this.threshold = window.innerHeight / 2;
        this.status = 0;
        this.button = $('#goto-page-top');
        this.button.on('click', function () {
            App.Utils.scrollTo($('html'), 0, 1000);
        });
    }

    BackToTop.prototype.checkPosition = function (currentPosition) {
        if (currentPosition > this.threshold) {
            if (0 == this.status) {
                this.status = 1;
                this.button.addClass('active');
            }
        } else {
            if (1 == this.status) {
                this.status = 0;
                this.button.removeClass('active');
            }
        }
    };

    var self = new BackToTop();
    App.ScrollerManager.add(self);
})(jQuery, App);


(function ($) {

    var headerBanner = $('#banners-header-line');
    if (!headerBanner.length) {
        return;
    }
    var headerHeight = $('#page-header').height();
    $('body').css('margin-top', headerHeight + 'px');

})(jQuery);

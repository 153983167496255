
(function ($, App) {

    var frame = $('.highlighted-offers .hs-frame');

    if (frame.length) {
        frame.find('.hs-left-button').on('click', function (event) {
            var items = frame.find('.hs-items');
            var shift = App.Utils.calculateHorizontalScrollShift(items);
            App.Utils.scrollLeft(items, shift, null, items);
        });

        frame.find('.hs-right-button').on('click', function (event) {
            var items = frame.find('.hs-items');
            var shift = App.Utils.calculateHorizontalScrollShift(items);
            App.Utils.scrollRight(items, shift, null, items);
        });

        frame.find('.hs-items').on('swiperight', function (event) {
            var items = frame.find('.hs-items');
            var shift = App.Utils.calculateHorizontalScrollShift(items);
            App.Utils.scrollLeft(items, shift, null, items);
        }).on('swipeleft', function (event) {
            var items = frame.find('.hs-items');
            var shift = App.Utils.calculateHorizontalScrollShift(items);
            App.Utils.scrollRight(items, shift, null, items);
        })
        ;
    }

})(jQuery, App);
